// React
import React, { useState, useEffect, useContext, memo } from "react";

// React router
import { useHistory } from "react-router";

// Api endpoints, hooks, and utility functions
import { getApplications } from "../../../api/endpoints/application";
import UpdateApplication from "./UpdateApplication/UpdateApplication";
import { useTranslation } from "react-i18next";
import {
  getGenericLocalStorage,
  setGenericLocalStorage,
} from "../../../utils/localSave";
import { useQuery } from "react-query";
import useTimeAgo from "../../hooks/useTimeAgo";

// Assets
import anonymousAvatar from "../../../assets/icons/anonymous-avatar.svg";

// Components
import { CurriculumVitae, Icon } from "../../../components";
import { Col, Grid, Row } from "../../../components/Grid";
import { Typography } from "../../../components/Typography";
import { IsLoading } from "../../style";
import {
  ApplicationWrapper,
  ApplicationTopBar,
  ApplicantMeta,
  ApplicantHeadings,
  ApplicantAvatar,
} from "./Application.styled";
import ApplicationStars from "./ApplicationStars/ApplicationStars";
import { ApplicantContactInfo } from "./ApplicantContactInfo/ApplicantContactInfo";
import StatusSelector from "./StatusSelector/StatusSelector";
import CVRating from "../../../components/CurriculumVitae/CVRating";
import ApplicationActionModal from "../../components/ApplicationCard/ApplicationActionModal";
import { UpdateLoading } from "../../../components/Loader/style";

// State management
import { DispatchErrorContext } from "../../../context/ErrorContext";
import {
  StatePositionContext,
  DispatchPositionContext,
} from "../../context/PositionContext";

const Application = memo(
  ({ manageShareholder, application, index, allApplications }) => {
    const { t } = useTranslation();
    // let { id } = useParams();
    const positionState = useContext(StatePositionContext);
    const positionDispatch = useContext(DispatchPositionContext);
    let id = positionState.appId;
    let history = useHistory();
    const [editView, setEditView] = useState(true);
    const [single, setSingle] = useState(null);
    const [positionTitle, setPositionTitle] = useState("");
    const [next, setNext] = useState(null);
    const [prev, setPrev] = useState(null);
    const [createdAt, setCreatedAt] = useState(null);
    const [count, setCount] = useState();
    const [total, setTotal] = useState(null);
    const [avatar, setAvatar] = useState(anonymousAvatar);
    const [confirmation, setConfirmation] = useState(false);

    const dispatchError = useContext(DispatchErrorContext);

    const isAnonymous = positionState.single.anon;

    useEffect(() => {
      //init edit view
      const admin_settings = getGenericLocalStorage("admin_settings", true);
      if (admin_settings && admin_settings.applicationEditViewOpen) {
        setEditView(admin_settings.applicationEditViewOpen === "open");
      }
    }, []);

    useEffect(() => {
      let admin_settings = getGenericLocalStorage("admin_settings", true);
      admin_settings = {
        ...admin_settings,
        applicationEditViewOpen: editView ? "open" : "closed",
      };
      setGenericLocalStorage("admin_settings", admin_settings, true);
    }, [editView]);

    const updateParentState = (newState) => {
      setConfirmation(newState);
    };

    //React query fetching of single application
    const fetchSingleApplication = async () => {
      const request = {
        selector: id,
        params: { includeSignedRequest: true },
      };
      return await getApplications(request);
    };
    const { data, isLoading, isError } = useQuery(
      ["single_application", id],
      fetchSingleApplication
    );
    useEffect(() => {
      //Handle error
      if (isError) {
        dispatchError({
          type: "set_error",
          payload: t("error.application.could_not_get_application"),
        });
        history.push("/404");
      }

      if (application) {
        setSingle(application[index]);
        setPositionTitle(application[index].position.title);
      } else if (data?.data[0]) {
        setSingle(data.data[0]);
        //Set position title state
        setPositionTitle(data.data[0].position.title);

        //Set next and prev pointers
        setNext(data.next);
        setPrev(data.prev);

        // Set total
        setTotal(data.total);

        // Set createdAt for calculating time ago
        setCreatedAt(data.data[0].createdAt);
      }
    }, [data, application, index]);

    useEffect(() => {
      // Use the filtered applications array
      const filteredApplications = allApplications?.data?.filter(
        // (app) => app.substatus === single.substatus
        (app) => app.substatus === (single?.substatus || "")
      );

      // Get the index of the selected application
      const selectedIndex = filteredApplications?.findIndex(
        (app) => app.id === single.id
      );

      // Set count based on the index
      setCount(selectedIndex + 1);
    }, [allApplications, single]);

    // Format days weeks month
    const timeAgo = useTimeAgo(createdAt);

    // Handle counter
    function handleNext() {
      const filteredApplications = allApplications?.data.filter(
        (app) => app.substatus === single.substatus
      );

      if (!filteredApplications || filteredApplications.length === 0) {
        return;
      }

      const currentIndex = filteredApplications.findIndex(
        (app) => app.id === single.id
      );
      const nextIndex = (currentIndex + 1) % filteredApplications.length;
      const nextApplication = filteredApplications[nextIndex];

      setCount(nextIndex + 1); // Update count first
      positionDispatch({ type: "application_id", payload: nextApplication.id });
    }

    function handlePrev() {
      const filteredApplications = allApplications?.data.filter(
        (app) => app.substatus === single.substatus
      );

      if (!filteredApplications || filteredApplications.length === 0) {
        return;
      }

      const currentIndex = filteredApplications.findIndex(
        (app) => app.id === single.id
      );
      const prevIndex =
        (currentIndex - 1 + filteredApplications.length) %
        filteredApplications.length;
      const prevApplication = filteredApplications[prevIndex];

      setCount(prevIndex + 1); // Update count first
      positionDispatch({ type: "application_id", payload: prevApplication.id });
    }

    // Modal for application buttons confirmation
    const [modalAction, setModalAction] = useState(null);
    const handleActionClick = (actionName) => {
      setModalAction(actionName);
    };

    useEffect(() => {
      if (single) {
        if (single.externalProfileImage) {
          setAvatar(single.externalProfileImage);
          return;
        }

        const { files } = single;

        if (files) {
          const profileImage = files.find(
            (fileObject) => fileObject.docType === "profileImage"
          );

          if (profileImage) {
            setAvatar(profileImage.signedRequest);
          } else {
            setAvatar(anonymousAvatar);
          }
        }
      }
    }, [single]);

    const updateSingleInParent = (newSingle) => {
      setSingle(newSingle);
    };

    const allApplicationsArray = allApplications?.data?.filter(
      (test) => test.substatus === (single?.substatus || "")
    );

    return (
      <>
        {confirmation && (
          <UpdateLoading success>
            {t("common.emailCopied", "E-mail copied to clipboard")}
          </UpdateLoading>
        )}
        {modalAction && (
          <ApplicationActionModal
            setModalAction={(state) => {
              setModalAction(state);
              if (modalAction === "delete") {
                handleNext();
              }
            }}
            modalAction={modalAction}
            applications={[single]}
          />
        )}
        <ApplicationWrapper
          applicantView={positionState.candidateView && !manageShareholder}
          manageShareholder={manageShareholder}
        >
          {!isLoading && single && single.applicant ? (
            <>
              {!manageShareholder && (
                <ApplicationTopBar>
                  <>
                    <div>
                      <Icon
                        icon="arrow_left"
                        mr="24"
                        hideOnDesktop
                        pointer
                        onClick={() =>
                          positionDispatch({
                            type: "candidate_view",
                            payload: false,
                          })
                        }
                      />
                      <Typography tag="p" nomargin large color="#52576A">
                        {timeAgo}
                      </Typography>
                    </div>
                    <div>
                      <Typography
                        tag="p"
                        nomargin
                        large
                        color="#52576A"
                      >{`${count} ${t("common.outOf", "out of")} ${
                        allApplicationsArray && allApplicationsArray.length > 0
                          ? allApplicationsArray.length
                          : 1
                      }`}</Typography>
                      <Icon
                        icon="next_prev"
                        onClick={handlePrev}
                        ml="24"
                        pointer
                      />
                      <Icon
                        icon="next_prev"
                        onClick={handleNext}
                        ml="24"
                        pointer
                        scale="-1"
                      />
                      <Icon
                        icon="collapse"
                        scale="-1"
                        ml="48"
                        hideOnMobile
                        pointer
                        onClick={() =>
                          positionDispatch({
                            type: "candidate_view",
                            payload: false,
                          })
                        }
                      />
                    </div>
                  </>
                </ApplicationTopBar>
              )}
              <Grid
                fluid
                flex
                heightSM="calc(100% - 70px - 64px);"
                heightMD="calc(100% - 70px - 44px);"
                manageShareholder={manageShareholder}
              >
                <Row
                  height="100%"
                  width="250px"
                  flexDirectionMD="column"
                  plMD="44"
                  prMD="44"
                  ptMD="26"
                  bg="rgba(243, 243, 245, 0.6);"
                  ml="0"
                  mr="0"
                  hideOnMobile
                  application
                >
                  <ApplicantAvatar
                    large
                    avatar={isAnonymous ? anonymousAvatar : avatar}
                  />
                  <ApplicantContactInfo
                    single={single}
                    isAnonymous={isAnonymous}
                    onUpdateState={updateParentState}
                  />
                  {Object.keys(single.competencies).length > 0 && (
                    <>
                      <Typography
                        tag="p"
                        bold
                        fontWeightLG="600"
                        nomargin
                        mb="10"
                        mt="15"
                      >
                        {t("common.skills", "Skills")}
                      </Typography>
                      <CVRating single={single.competencies} />
                    </>
                  )}
                  {Object.keys(single.languages).length > 0 && (
                    <>
                      <Typography
                        tag="p"
                        bold
                        fontWeightLG="600"
                        nomargin
                        mb="10"
                        mt="15"
                      >
                        {t("common.languages", "Languages")}
                      </Typography>
                      <CVRating single={single.languages} />
                    </>
                  )}
                </Row>
                <Row
                  ml="0"
                  mr="0"
                  pb="50"
                  overflow="hidden"
                  height="100%"
                  widthMD="calc(100% - 250px)"
                  cv
                  manageShareholder
                >
                  <Col mlMD="26" mrMD="26">
                    <ApplicantMeta>
                      <ApplicantAvatar
                        avatar={isAnonymous ? anonymousAvatar : avatar}
                        hideOnDesktop
                      />
                      <ApplicantHeadings>
                        <Typography
                          mbLG="12"
                          fontWeightLG="300"
                          tag="p"
                          small
                          uppercase
                          bold
                          lowOpacity
                          letterSpaced
                          nomargin
                          mb="5"
                        >
                          {single.position.title}
                        </Typography>
                        <Typography
                          tag="h1"
                          customSize="20"
                          capitalize
                          mb="5"
                          isAnonymous={isAnonymous}
                        >{`${single.applicant.candidate.firstName} ${single.applicant.candidate.lastName}`}</Typography>
                        <ApplicationStars
                          id={single}
                          disabled={manageShareholder}
                        />
                      </ApplicantHeadings>
                      {!manageShareholder && (
                        <StatusSelector application={single} hideOnMobile />
                      )}
                    </ApplicantMeta>
                    <ApplicantContactInfo single={single} hideOnDesktop />
                    <CurriculumVitae data={single} />
                  </Col>
                </Row>
              </Grid>
              {!manageShareholder && (
                <UpdateApplication
                  single={single}
                  handleActionClick={handleActionClick}
                  updateSingleInParent={updateSingleInParent}
                />
              )}
            </>
          ) : (
            <IsLoading />
          )}
        </ApplicationWrapper>
      </>
    );
  }
);

export default Application;
