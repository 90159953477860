// React 
import React from 'react';

// Utility functions, hooks, actions, and translations
import { useTranslation } from 'react-i18next';
import {
  APPLICATION_CARD_ACTIONS,
  SUBSTATUS_APPLICATION_CARD_ACTIONS,
} from '../../../components/ApplicationList/actions';
import useApplicationActions from '../../../hooks/useApplicationActions';
import Dropdown from 'react-dropdown';

// Components
import { StatusSelectorWrapper } from './StatusSelector.styled';

const StatusSelector = ({ application, hideOnMobile }) => {
  const { t } = useTranslation();

  const { loading: applicationActionLoading, handleActionTaken } =
    useApplicationActions(
      'set_substatus',
      () => {},
      [application],
      {},
      true
    );

    const handleStatusUpdate = async (selectedOption) => {
      const substatus = selectedOption?.value || '';
      await handleActionTaken({ substatus });
    };

  const dropdownOptions = [
    { value: '', label: t('component.application_status_list.submitted', 'Submitted') },
    ...APPLICATION_CARD_ACTIONS.filter((action) =>
      SUBSTATUS_APPLICATION_CARD_ACTIONS.includes(action.name)
    ).map((action) => ({
      value: action.name,
      label: t(action.i18nKey[0], action.i18nKey[1])
    }))
  ];
  
  return (
    <StatusSelectorWrapper>
  <Dropdown
        options={dropdownOptions}
        placeholder={t('common.statusSelect')}
        name="substatus"
        value={dropdownOptions.find(option => option.value === application.substatus)}
        onChange={handleStatusUpdate}
        // controlClassName="dropdown-control"
        // menuClassName="dropdown-menu"
        // arrowClassName="dropdown-arrow"
        disabled={application.status !== 'submitted' || applicationActionLoading}
        hideLabel
        hideOnMobile={hideOnMobile}
      />
      </StatusSelectorWrapper>
  );
};

export default StatusSelector;
