// React
import React, { useState, useEffect } from "react";

// Utility functions, hooks, and API endpoints
import { updateApplication } from "../../../../api/endpoints/application";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import useApplicationActions from "../../../hooks/useApplicationActions";

// Components
import { FileField } from "../../../../components";
import { PrimaryButton } from "../../../../components/Button";
import { CETextField } from "../../../../components/Input/Input";
import { Col } from "../../../../components/Grid";
import { initialField } from "../../../../components/FileField/reducer";
import { Icon } from "../../../../components";

// Styled components
import {
  AddNotesContainer,
  Notes,
  ButtonGroupContainer,
} from "./UpdateApplication.styled";

const UpdateApplication = ({
  single,
  handleActionClick,
  updateSingleInParent,
}) => {
  const { t } = useTranslation();
  const [summary, setSummary] = useState(single.recruiterSummary);
  const [changesSaved, setChangesSaved] = useState(true);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const { loading: applicationActionLoading, handleActionTaken } =
    useApplicationActions(
      "set_substatus",
      () => {},
      [single], // You may need to adjust this dependency array
      {},
      true
    );

  //to invalidatequery after update
  const queryClient = useQueryClient();
  const invalidateQuery = () => {
    queryClient.invalidateQueries(["single_application", `${single.id}`]);
  };

  const handleChange = (e) => {
    setSummary(e.target.textContent);
    setChangesSaved(false);
  };

  //save every 5 seconds
  useEffect(() => {
    let timer = setTimeout(() => {
      if (!changesSaved) {
        submitSummary();
      }
    }, 500);

    return () => {
      clearTimeout(timer);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changesSaved, summary]);

  //SAVE RECRUITER SUMMARY
  const submitSummary = async () => {
    try {
      // setIsSubmitting(true);
      const request = {
        selector: single.id,
        body: { recruiterSummary: summary },
      };
      const response = await updateApplication(request);
      if (response.success) {
        //saved success
        setChangesSaved(true);
        invalidateQuery();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleBlur = async (e) => {
    e.preventDefault();
    submitSummary();
  };

  // SHORT LIST APPLICATION
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    try {
      const newSubstatus =
        single.substatus === "shortlisted" ? "applied" : "shortlisted";

      // Use handleActionTaken to update the substatus
      await handleActionTaken({ substatus: newSubstatus });

      const request = {
        selector: single.id,
        body: { substatus: newSubstatus },
      };
      // Assuming you have an 'updateApplication' function that makes the API call
      const response = await updateApplication(request);
      if (response.success) {
        // Update the substatus in the parent component through the callback function
        updateSingleInParent({ ...single, substatus: newSubstatus });
        invalidateQuery();
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  //Save file to application
  const handleUpload = async (fileObject) => {
    try {
      setLoading(true);
      const request = {
        selector: single.id,
        body: { addFiles: [fileObject.uuid] },
      };
      await updateApplication(request);
      invalidateQuery();
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  //Remove file from application
  const handleRemove = async (fileObject) => {
    try {
      setLoading(true);
      const request = {
        selector: single.id,
        body: { removeFiles: [fileObject.uuid] },
      };
      await updateApplication(request);
      invalidateQuery();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  //Update file handler state
  useEffect(() => {
    if (single.files) {
      setUploadedFiles(
        single?.files
          .filter((file) => file.docType === "file")
          .map((file, index) => {
            return {
              ...initialField,
              id: index,
              hasFile: true,
              savedFile: file,
            };
          })
      );
    }
  }, [single]);

  return (
    <>
      <AddNotesContainer>
        <Notes>
          <CETextField
            name="recruiterSummary"
            trackValue={summary}
            value={summary}
            onInput={handleChange}
            onBlur={handleBlur}
            feedbackOn={changesSaved}
            placeholder={t("common.addNotes", "Add notes...")}
            text={single.recruiterSummary}
            noBorder
            padding="0 0 10px 0;"
            contentEditable
            pbMD="20"
            notes
          />
          <FileField
            docType="file"
            type="document"
            notes
            onUpload={handleUpload}
            onRemove={handleRemove}
            initialFiles={uploadedFiles}
            attachment={true}
          />
        </Notes>
        <ButtonGroupContainer>
          <Col mlMD="0" mrMD="0">
            <PrimaryButton
              tertiary
              transparent
              onClick={() => {
                handleActionClick("delete");
              }}
            >
              {t("common.deleteCandidate", "Delete")}
              <Icon mr="10" icon="trash" />
            </PrimaryButton>
          </Col>
          <Col mlMD="0" mrMD="0" right>
            <PrimaryButton
              onClick={() => {
                handleActionClick("reject");
              }}
              tertiary
              white
              mr="20"
              disabled={single.status !== "submitted"}
            >
              {t(
                "component.card.application.confirm_reject_button_label",
                "Send rejection"
              )}
              <Icon mr="10" icon="circle_cross" />
            </PrimaryButton>
            <PrimaryButton red tertiary onClick={handleClick}>
              {single.substatus === "shortlisted" ? (
                <>
                  {t("common.shortlisted")}
                  <Icon mr="10" icon="minus" />
                </>
              ) : (
                <>
                  {t("common.shortlist")}
                  <Icon mr="10" icon="thick_plus" />
                </>
              )}
            </PrimaryButton>
          </Col>
        </ButtonGroupContainer>
      </AddNotesContainer>
    </>
  );
};

export default UpdateApplication;
