// React
import { useState, useEffect, useContext } from 'react';

// Utility hooks and functions
import { useTranslation } from 'react-i18next';
import useApplicationActions from '../../hooks/useApplicationActions';

// Components
import { BoxLoader, Icon, Modal } from '../../../components';
import { PrimaryButton } from '../../../components/Button';
import { Row, Col } from '../../../components/Grid';
import { Typography } from '../../../components/Typography';

// State management
import { APPLICATION_CARD_ACTIONS } from '../ApplicationList/actions';
import { ApplicationParamsContext } from '../ApplicationList/ApplicationContextWrapper';
// import EditRejectionEmail from '../EditRejectionEmail';

const ApplicationActionModal = ({
  modalAction,
  setModalAction,
  applications,
}) => {
  const { t } = useTranslation();

  const params = useContext(ApplicationParamsContext);

  const { loading, handleActionTaken } = useApplicationActions(
    modalAction,
    setModalAction,
    applications,
    { positionId: params?.state?.params?.excludePositionId }
  );

  const [action, setAction] = useState(null);

  useEffect(() => {
    const action = APPLICATION_CARD_ACTIONS.find(
      (ac) => ac.name === modalAction
    );
    setAction(action);

    if (action.skipModal) {
      handleActionTaken();
    }
  }, [modalAction]);


  return (
    <Modal openAnimation title={action?.modal?.header && t(action?.modal?.header[0])} onClose={() => setModalAction(null)}>
        <> 
          <Row paddingMD={32} flexDirectionMD="column" alignCenter>
            <Col>
              <Typography tag="p" center mw="450" alignCenter>
              {action?.modal?.description &&
                  t(action?.modal?.description[0])}
        </Typography>
            </Col>
            <Col vCenter hCenter flexDirection="column-reverse">
              <PrimaryButton transparent mt="18"  onClick={() => setModalAction(null)}>
            {t('common.cancel')}
            <Icon icon="cross" mr="10" invert />
          </PrimaryButton>
              <PrimaryButton
                onClick={handleActionTaken}
                red
                mt={32}
              >
                {action?.modal?.button && t(action?.modal?.button[0])}
                <Icon icon="trash" mr="10" invert />
              </PrimaryButton>
            </Col>
          </Row>
        </>
    </Modal>
  );
};

export default ApplicationActionModal;
