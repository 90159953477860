// React
import React, {useState, useEffect, useContext} from 'react'

// Styled components
import { KanbanCardContainer, KanbanCardAvatar, KanbanContent, KanbanContactInfo, KanbanLinkContainer, IsInvited } from './KanbanCard.styled'
import { Flex } from '../../../../style'
import { Row } from '../../../../../components/Grid'
import { DesktopOnly } from '../../../../style'

// Components
import KanbanCardStars from './KanbanCardStars/KanbanCardStars'

// Routing
import { useHistory } from 'react-router'

// Assets
import anonymousAvatar from '../../../../../assets/icons/anonymous-avatar.svg';
import avatarIcon from '../../../../../assets/icons/avatar.svg';

// State management
import { DispatchPositionContext, StatePositionContext } from '../../../../context/PositionContext'
import { Icon } from '../../../../../components'
import { Typography } from '../../../../../components/Typography'

const KanbanCard = ({application, activePositionId, setActivePositionId, initial, setInitial, manageShareholder}) => {

    const [avatar, setAvatar] = useState(anonymousAvatar);
    useEffect(() => {
      const getProfileImage = (application) => {
        if (application.externalProfileImage) {
          setAvatar(application.externalProfileImage);
          return;
        }
        const { files } = application;
        if (files) {
          const profileImage = files.find(
            (fileObject) => fileObject.docType === 'profileImage'
          );
          if (profileImage) {
            setAvatar(profileImage.signedRequest);
          }
        }
      };
      getProfileImage(application);
    }, [application]);

    const history = useHistory();

    const positionDispatch = useContext(DispatchPositionContext);
    const positionState = useContext(StatePositionContext);
    const isAnonymous = positionState.single.anon; 

    const [isActive, setIsActive] = useState(application.id === activePositionId);

    useEffect(() => {
      setIsActive(application.id === positionState.appId);
    }, [application.id, activePositionId, positionState.appId]);

  return (
    <KanbanCardContainer {...(isActive ? {active: true} : {})} initial={initial || !positionState.candidateView}>
        <Row ml="0" mr="0">
        <Flex height="100%" position="relative">
          {application.status === 'invited' && (
            <IsInvited>
             <p>INVITED</p>
            </IsInvited>
          )}
        <div>
        <KanbanCardAvatar avatar={isAnonymous ? anonymousAvatar : avatar}/>
        </div>
        <KanbanContent isAnonymous={isAnonymous}>
        <h3>{`${application.applicant.candidate.firstName} ${application.applicant.candidate.lastName}`}</h3>
        <KanbanCardStars application={application} disabled={manageShareholder} />
        </KanbanContent>
        </Flex>
        </Row>
        <DesktopOnly>
            <Row ml="0" mr="0">
                <KanbanContactInfo isAnonymous={isAnonymous}>
                    <p>  {application.applicant.candidate.email.length > 25
    ? `${application.applicant.candidate.email.slice(0, 20)}....`
    : application.applicant.candidate.email}</p>
                    <p>{application.applicant.candidate.phoneNumber}</p>
                </KanbanContactInfo>
            </Row>
        </DesktopOnly>
<KanbanLinkContainer onClick={() => {
  if(application.status !== 'invited') {
  positionDispatch({ type: 'application_id', payload: application.id });
  positionDispatch({ type: 'application_status', payload: application.substatus });
  positionDispatch({ type: 'candidate_view', payload: true });
  }
  if (!manageShareholder) {
    setActivePositionId(application?.id);
    setInitial(false);
  }
}} />
        {/* onClick={() => history.push(`/candidate/${application.application.id}`)} */}
    </KanbanCardContainer>
  )
}


export default KanbanCard